// Journal 4.5.2
// Bootswatch


// Variables ===================================================================

$web-font-path: 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap' !default;
@import url($web-font-path);

// Navbar ======================================================================

.bg-dark {
  background-color: #000 !important;
}

.bg-light {
  background-color: $white !important;
  color: $black;
  border: 1px solid $gray-200;

  &.navbar-fixed-top {
    border-width: 0 0 1px 0;
  }

  &.navbar-fixed-bottom {
    border-width: 1px 0 0 0;
  }
}

.navbar {
  background-color: $gray-200;
  padding-top: 1rem;
  padding-left: 1rem;
  margin-bottom: 2rem;
  font-size: 13px;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
}

.navbar-brand {
  padding-top: 0.5rem;
  font-size: inherit;
  font-weight: $headings-font-weight;
  text-transform: uppercase;
}

// Buttons =====================================================================

.btn {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;

  &-secondary,
  &-warning {
    color: $white;
  }
}

// Typography ==================================================================

// Tables ======================================================================

// Forms =======================================================================

// Navs ========================================================================

.pagination {
  a:hover {
    text-decoration: none;
  }
}

// Indicators ==================================================================

// Progress bars ===============================================================

// Containers ==================================================================
